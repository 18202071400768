import React from "react";
import { Button, Card, Table } from "react-bootstrap";
import { BASE_URL } from "../../constants/URL";
import { useModals } from "@mantine/modals";
import { deleteWeeklyFile } from "../../actions/WeeklyFile";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Text } from "@mantine/core";
import StudentView from "./StudentView";

const FileList = ({ files, deleteWeeklyFile, setFiles, hideDelete }) => {
  const modals = useModals();
  const deleteHandeler = (id) =>
    modals.openConfirmModal({
      title: "Delete this file",
      centered: true,
      children: (
        <Text size="md">
          Are you sure you want to delete this file? This action is destructive
          and you can not undo it.
        </Text>
      ),
      labels: { confirm: "Delete File", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: () => confirmHandeler(id),
    });

  const confirmHandeler = async (id) => {
    let check = await deleteWeeklyFile(id);
    if (check !== false) {
      toast.success("File deleted successfully");
      setFiles(files.filter((f) => f.id !== id));
    } else {
      toast.error("Something went wrong");
    }
  };

  if (files.length <= 0) {
    return <h3 className="text-center py-4">No Files Found</h3>;
  }
  return (
    <div className="pt-4">
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <h4>Weekly Files</h4>
          <div className={" pt-4 pb-3"}>
            <div className="">
              <StudentView data={files[0]} response={files[0]} />
            </div>
          </div>

          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Serial</th>
                <th>Student Name</th>
                <th>Week</th>
                <th>File</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, index) => (
                <tr key={index}>
                  <td>{file?.student?.uid}</td>
                  <td>{file?.student?.name}</td>
                  <td>{file?.week}</td>
                  <td>
                    <a
                      href={`${BASE_URL}/api/weekly_upload/${file.file}`}
                      className="btn btn-primary btn-sm"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download
                    </a>
                    {hideDelete ? (
                      <></>
                    ) : (
                      <Button
                        variant="danger"
                        size="sm"
                        className="ms-3"
                        onClick={() => deleteHandeler(file.id)}
                      >
                        Delete
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default connect(null, { deleteWeeklyFile })(FileList);
