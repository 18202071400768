import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getGradeList } from "../../actions/Grade.action";
import ViewWeeklyFiles from "../../components/ViewWeeklyFiles/ViewWeeklyFiles";
import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";

const PublicViewWeeklyFilePage = ({ grades, getGradeList }) => {
  useEffect(() => {
    getGradeList();
  }, []);
  return (
    <div className="bg_light">
      <PublicLayout title={`Weekly Files`}>
        <ViewWeeklyFiles grades={grades} hideDelete />
      </PublicLayout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  grades: state.grade.grade,
});

export default connect(mapStateToProps, { getGradeList })(
  PublicViewWeeklyFilePage
);
