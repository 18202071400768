import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { Link } from "react-router-dom";
import styles from "./ViewRangedWeeklyFiles.module.css";

import { connect } from "react-redux";
import { getRangedWeeklyFiles } from "../../actions/WeeklyFile";
import { years } from "../../constants/MonthsAndYears";
import { weekArray } from "../../constants/WeekArray";
import FileList from "./FileList";
import { toast } from "react-toastify";

const ViewRangedWeeklyFiles = ({
  grades,
  getRangedWeeklyFiles,
  hideDelete = false,
}) => {
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [start, setStart] = useState("1");
  const [end, setEnd] = useState("1");
  const [uid, setUid] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(null);

  const selectHandeler = async () => {
    if (!uid) {
      toast.error("Please enter a student serial number");
      return;
    }
    if (start > end) {
      toast.error("Start week should be less than end week");
      return;
    }
    if (start < 1 || end < 1) {
      toast.error("Week should be greater than 0");
      return;
    }

    if (start > 52 || end > 52) {
      toast.error("Week should be less than 53");
      return;
    }
    setLoading(true);
    try {
      const list = await getRangedWeeklyFiles(
        year,
        grade,
        shift,
        batch,
        start,
        end,
        uid
      );
      if (list !== false) {
        setFiles(list);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}

            {grade !== "" && shift !== "" && batch !== "" && uid ? (
              <Col
                md={3}
                className="d-md-flex d-none jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  disabled={loading}
                  className="btn_primary"
                >
                  {loading ? "Loading..." : "Select"}
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col md={3} className="py-3">
              <InputGroup className="mb-3 d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center ">
                  <label htmlFor="uid" className="d-block">
                    Student Roll
                  </label>
                </div>
                <Form.Control
                  placeholder="Type roll of the student..."
                  name="uid"
                  type="number"
                  className={`${styles.input} w-100`}
                  value={uid}
                  onChange={(e) => {
                    setUid(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="start" className="d-block">
                  Start Week
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setStart(e.target.value);
                }}
                id="start"
                value={start}
                name="start"
              >
                {weekArray.map((item) => (
                  <option key={item} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="end" className="d-block">
                  End Week
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setEnd(e.target.value);
                }}
                id="end"
                value={end}
                name="end"
              >
                {weekArray.map((item) => (
                  <option key={item} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>{" "}
            {grade !== "" && shift !== "" && batch !== "" && uid ? (
              <Col
                md={3}
                className="d-flex d-md-none jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  disabled={loading}
                  className="btn_primary"
                >
                  {loading ? "Loading..." : "Select"}
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Card.Body>
      </Card>

      {files ? (
        <FileList files={files} setFiles={setFiles} hideDelete={hideDelete} />
      ) : (
        <></>
      )}
    </Container>
  );
};

export default connect(null, { getRangedWeeklyFiles })(ViewRangedWeeklyFiles);
